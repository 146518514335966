<template>
  <div class="item content">
    <p>Общество с ограниченной ответственностью "Научно-производственное предприятние электронно-компонентная база" занимается разработкой и производством прецизионных ЧИП-резисторов применяемых при изготовлении высокотехнологичной продукции.</p>
    <p>Деятельность ООО "НПП ЭКБ" основана на соблюдении установленных законодательством России и международными организациями норм и правил и наличии необходимых разрешительных документов (лицензий, аттестатов аккредитации и других документов).</p>
  </div>
</template>

<style scoped>
.content {
  grid-area: content;
  padding: 10px 20px;
  text-shadow: 1px 1px 1px #000000;
  line-height: calc( (100vw - 480px)/(1280 - 480) * (28 - 24) + 24px);
  /*font: 900 20px Arial;*/
  border-left: 1px solid rgb(255,255,255,.4);
}
</style>






