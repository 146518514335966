<template>
  <body>
  <div class="main">
  <!--    <router-link to="/">Home</router-link> |-->
<!--    <router-link to="/about">About</router-link>-->
 <header-block/>
 <nav-block/>
 <sidebar-block/>
 <footer-block/>
  <router-view/>
  </div>
  </body>
</template>
<script>
// import HeaderBlock from "@/components/HeaderBlock.vue";
export default {
  // components: {HeaderBlock}
}
</script>

<style>
body {
  /*float: left;*/
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: rgb(96, 143, 204);

}

.main {
  height: 100vh;
  background-color: rgb(96, 143, 204);
  /*border: 1px solid rgb(24, 84, 133);*/
  /*text-align: center;*/
  font-size: calc( (100vw - 480px)/(1280 - 480) * (20 - 14) + 14px);
  /*font-size: 18px;*/
  font-family: sans-serif;
  color: #fff;
  display: grid;
  grid-template-areas:
'header header'
'sidebar content'
'footer footer';
  /*grid-gap: 5px;*/
  grid-template-columns: minmax(150px,200px) 1fr;
  grid-template-rows: minmax(100px,auto) 1fr auto;
}


@media (max-width: 576px) {
  .main{
    grid-template-columns: 1fr;
    grid-template-areas:
"header"
"nav"
"content"
"footer";
    grid-template-rows: auto auto 1fr auto;
  }
}
</style>
<script setup>
import HeaderBlock from "@/components/HeaderBlock.vue";
import NavBlock from "@/components/NavBlock.vue";
import SidebarBlock from "@/components/SidebarBlock.vue";
import FooterBlock from "@/components/FooterBlock.vue";
</script>