<template>
  <div class="item content">
    <img src="@/assets/img/certekb.png">
    <p>Сертификат соответствия № ЭС 06.093.03.53-2023 удостоверяет, что система менеджмента качества, распространяющаяся на разработку и производство продукции класса ЕК 001-2020: 5905  соответствует требованиям ГОСТ Р ИСО 9001-2015, ГОСТ РВ 0015-002-2020, ГОСТ РВ 0020-57.412-2020</p>
  </div>
</template>

<style scoped>
.content {
  grid-area: content;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
  /*grid-template-rows: auto;*/
  justify-items: center;
  /*grid-gap: 10px;*/
  /*margin-left: 20px;*/
  text-shadow: 1px 1px 1px #000000;
  line-height: calc((100vw - 480px) / (1280 - 480) * (26 - 20) + 20px);
  font-size: calc( (100vw - 480px)/(1280 - 480) * (18 - 12) + 12px);
  /*font: 900 20px Arial;*/
  border-left: 1px solid rgb(255,255,255,.4);

  /*border: 3px solid red;*/
  /*max-width: 768px;*/
}
img{
  height: auto;
  width: 90%;
  max-width: 400px;
  /*margin-left: auto;*/
 margin: 15px 15px;
  padding: 10px;
  /*border: 3px solid red;*/
  /* max-height: 565px;*/
  }
p{
  margin:0px;
  /*margin: 15px 0px;*/
  /*border: 3px solid red;*/
  padding: 30px 10px;
  /*max-height: 545px;*/

}
@media (max-width: 576px) {
  .content {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
  img{
    margin: 0px;

  }
 p{
  padding: 0px 10px 5%;
 }
}
</style>