<template>
  <div class="item header">
    <img class="imgh" src="@/assets/img/logo3.png" @click="$router.push('/')">
    <div class="tel">
      <!--          <p class="head2">Общество с ограниченной ответственностью</p>-->
      <!--          <p class="head2">"Научно-производственное предприятние электронно-компонентная база"</p>-->
      <p class="head2">Телефон: <a href="tel:+74951476025"><img class="imgt" src="@/assets/img/tel1.png"></a> +7 (495) 147-60-25, e-mail: info@nppekb.ru</p>
    </div>
  </div>
</template>

<script>
export default {
  // name: "HeaderBlock"
}
</script>

<style scoped>
.header{
  /*padding: 50px;*/
  grid-area: header;
  background-color:#ffffff;
  padding: 0;
  display: flex;
  color: #000000;
  /*justify-content: center;*/
  align-items: center;
  flex-flow: row wrap;
  justify-content: space-around;
  /*width: max-content;*/
  /*width: -moz-fit-content;*/

  /*height: fit-content;*/
  /*flex-flow: column nowrap;*/
  /*justify-content: flex-start;*/
  /*align-items: center;*/
}
.imgh{
  background:  no-repeat;
  /*margin-bottom: 15px;*/
}
.imgh:hover{
  cursor: pointer;
}

.head2{
  display: flex;
  align-items: center;
  /*line-height: 3px;*/
  /*font-size: 0.8em;*/
  /*margin: 10px 0px 0px;*/
  /*border: 1px solid red;*/
}
</style>