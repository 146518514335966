<template>
  <div class="item nav">
    <div class="navbar-main">
      <div class="nav-item main-page" @click="$router.push('/')">О компании</div>
    </div>
      <div class="navbar-items">
      <div class="nav-item prod-page" @click="$router.push('/prod')">Продукция</div>
      <div class="nav-item cert-page" @click="$router.push('/cert')">Сертификат соответсвия СМК</div>
      <div class="nav-item mail-page" @click="$router.push('/cont')">Контакты</div>
      </div>
  </div>
</template>

<script>
export default {
  name: "NavBlock"
}
</script>

<style scoped>
.nav{
  display: none;
}
@media (max-width: 576px) {

  .nav{
    grid-area: nav;
    display: grid;
    grid-template-columns: 1fr 3fr ;
    grid-gap: 10px;
    align-items: center;
    padding: 1% 5px;
    text-align: center;
    font-size: calc( (100vw - 400px)/(576 - 380) * (16 - 13) + 13px);
    background-color: RGBA(248, 249, 250);
    color: rgba(0, 0, 0, 0.5);
  }

  .navbar-items{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .prod-page{
    flex-grow: 1;
  }
  .mail-page{
    flex-grow: 1;
  }
  .cert-page {
    flex-grow: 0;
    max-width: 130px;
  }
  .nav-item{cursor: pointer;}
  .main-page{ color: rgba(0, 0, 0, 0.9); }
  .prod-page:hover { color: rgba(0, 0, 0, 0.9); }
  .cert-page:hover { color: rgba(0, 0, 0, 0.9); }
  .mail-page:hover { color: rgba(0, 0, 0, 0.9); }
}
</style>