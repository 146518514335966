<template>
  <div class="item sidebar">
    <button class="btn btn1" @click="$router.push('/')">О компании</button>
    <button class="btn btn2" @click="$router.push('/prod')">Продукция</button>
    <button class="btn btn3" @click="$router.push('/cert')">Сертификат соответсвия СМК</button>
    <button class="btn btn4" @click="$router.push('/cont')">Контакты</button>
  </div>
</template>

<script>
export default {
  name: "SidebarBlock"
}
</script>

<style scoped>
.sidebar {
  grid-area: sidebar;
  align-self: start;
  display: flex;
  flex-flow: column nowrap;
  padding: 0px;
  border:none;
  font-size: 16px;

}
.btn{
  text-shadow: 0px 1px 0px #000000;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  padding: .7em 1em calc(.8em + 3px);
  border-radius: 3px;
  background-color: rgb(96, 143, 204);
  border-color: rgb(255,255,255,.3);
  /*background: rgb(64,199,129);*/
  /*background: rgb(24, 84, 133);*/
  /*background: #0174c2;*/
  box-shadow: 0 -1px rgb(255,255,255,.1) inset;
  transition: 0.2s;
}
.btn:hover { background: rgba(255,255,255,.2);
  cursor: pointer;}
.btn:active { background: white; }

@media (max-width: 576px) {
  .sidebar {
    display: none;
  }
}
</style>