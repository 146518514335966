<template>

  <yandex-map :coords="coords">
    <ymap-marker
        marker-id="123"
        :coords="coords"
        :zoom="zoom"
        :icon="markerIcon"
    ></ymap-marker>
  </yandex-map>
</template>

<script>

export default {

  name: 'MapComponent',
  data: () => ({
      coords: [ 55.914938, 37.748942],
    zoom: 10,
  }),
};
</script>
<style>
.ymap-container {
  height: 350px;
  cursor: pointer;
  /*border: 3px solid red;*/
  padding: 10px;
  max-width: 600px;
}
</style>