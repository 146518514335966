import {createRouter, createWebHistory} from 'vue-router'
import HomePage from "@/views/HomePage.vue";
import ProdPage from "@/views/ProdPage.vue";
import CertPage from "@/views/CertPage.vue";
import ContactPage from "@/views/ContactPage.vue";

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomePage
    },
    {
        path: '/prod',
        name: 'prod',
        component: ProdPage
        // component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    },
    {
        path: '/cert',
        name: 'cert',
        component: CertPage
    },
    {
        path: '/cont',
        name: 'cont',
        component: ContactPage
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
